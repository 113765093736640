import React from "react";
import Layout from "../components/layout";
import ResourcesWrapper from "../components/resources/resources-wrapper.component";
import UsefulDocuments from "../components/useful-documents/useful-documents.component";

import "../styles/app.scss";

const StandAloneResources = () => {
  return (
    <Layout
      title="Resources"
      metaDescription="To help you find what you\'re looking for, we've grouped our lending criteria into key categories. You can browse by category or use the search function to jump straight to the relevant entry."
    >
      <section className="page-section row">
        <div className="col-xs-12 col-md-6 col-lg-7">
          <h1 className="page-title page-title--small text-center">Resource</h1>
          <ResourcesWrapper />
        </div>
        <div className="col-xs-12 col-md-6 col-lg-4 col-lg-offset-1 margin-bottom-double">
          <h1 className="page-title page-title--small page-title--resource">Useful documents</h1>
          <section className="splitted-section">
            <UsefulDocuments />
          </section>
        </div>
      </section>
    </Layout>
  );
};
export default StandAloneResources;
